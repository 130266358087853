import "core-js/modules/es.array.concat";
import _toConsumableArray from "/var/jenkins_home/workspace/\u7763\u8D1Dvideo\u6D4B\u8BD5\u7248/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import Vue from 'vue';
import VueRouter from 'vue-router';
import videoLookRouter from './modules/videos/video-look';
import videoCheckRouter from './modules/videos/video-check';
Vue.use(VueRouter);
export var constantRoutes = [].concat(_toConsumableArray(videoLookRouter), _toConsumableArray(videoCheckRouter));
export default new VueRouter({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});