/**
 * 视频模块-用户相关接口
 *
 * @file table.js
 */
import request from '@/utils/request'; // 获取门店可选检查表

export function getAuthUserInfo() {
  return request({
    url: '/get/manager/info',
    method: 'get'
  });
}