var TokenKey = 'Uniapp-Token';
var UserInfo = 'userInfo';
import { getAuthUserInfo } from '@/api/videos/user';
export function getToken() {
  return localStorage.getItem(TokenKey);
}
export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}
export function removeToken() {
  return localStorage.removeItem(TokenKey);
} // 获取缓存用户信息

export function getStorageUserInfo() {
  var userInfo = localStorage.getItem(UserInfo);

  if (userInfo) {
    return userInfo;
  } else {
    // 重新获取
    storageUserInfo();
    return localStorage.getItem(UserInfo);
  }
} // 缓存用户信息

export function storageUserInfo() {
  getAuthUserInfo().then(function (res) {
    if (res.code === 200) {
      // 缓存用户信息
      localStorage.setItem(UserInfo, res.data.customerName);
    }
  });
}