import "core-js/modules/es.object.to-string";

/**
 * 视频查看
 *
 * @file video-look.js
 */
var videoLookRouter = [{
  path: '/videos/look/select/shop',
  name: 'VideoLookSelectShop',
  meta: {
    title: '选择门店'
  },
  component: function component() {
    return import('@/views/videos/video-look/select-shop');
  }
}, {
  path: '/videos/look/all/shop',
  name: 'VideoLookAllShop',
  meta: {
    title: '选择门店架构'
  },
  component: function component() {
    return import('@/views/videos/video-look/all-shop');
  }
}, {
  path: '/videos/look/home',
  name: 'VideoLookHome',
  meta: {
    title: '视频查看'
  },
  component: function component() {
    return import('@/views/videos/video-look/video-look');
  }
}];
export default videoLookRouter;