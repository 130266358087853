import "core-js/modules/es.object.to-string";

/**
 * 视频巡查打分
 *
 * @file video-check.js
 */
var videoCheckRouter = [{
  path: '/videos/check/select/shop',
  name: 'VideoCheckSelectShop',
  meta: {
    title: '选择门店'
  },
  component: function component() {
    return import('@/views/videos/video-check/select-shop');
  }
}, {
  path: '/videos/check/all/shop',
  name: 'VideoCheckAllShop',
  meta: {
    title: '选择门店架构'
  },
  component: function component() {
    return import('@/views/videos/video-check/all-shop');
  }
}, {
  path: '/videos/check/score',
  name: 'VideoCheckScore',
  meta: {
    title: '视频巡查'
  },
  component: function component() {
    return import('@/views/videos/video-check/check');
  }
}, {
  path: '/videos/check/submit',
  name: 'VideoCheckSubmit',
  meta: {
    title: '视频巡查预览'
  },
  component: function component() {
    return import('@/views/videos/video-check/submit');
  }
}];
export default videoCheckRouter;