import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import axios from 'axios';
import { getToken, removeToken } from '@/utils/auth'; // import store from '@/store'

import { Toast, Confirm, Alert, Notify, Loading } from 'vue-ydui/dist/lib.rem/dialog';
import md5 from 'js-md5';
import { getUuid } from '@/utils/common';
Vue.prototype.$dialog = {
  confirm: Confirm,
  alert: Alert,
  toast: Toast,
  notify: Notify,
  loading: Loading
};
var md5Salt = process.env.VUE_APP_MD5_SALT;
var version = process.env.VUE_APP_VERSION;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'; // 创建axios实例

var service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000
}); // request拦截器

service.interceptors.request.use(function (config) {
  if (getToken()) {
    var token = 'Bearer ' + getToken();
    var nonce = getUuid();
    var timestamp = new Date().getTime();
    config.headers['Authorization'] = token;
    config.headers['nonce'] = nonce;
    config.headers['timestamp'] = timestamp;
    config.headers['sign'] = md5(token + md5Salt + timestamp + "" + nonce + version);
  }

  return config;
}, function (error) {
  Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(function (res) {
  var code = res.data.code;

  if (code === 401) {
    Toast({
      mes: '登录已过期，请重新登录',
      timeout: 1500
    }); // 清除缓存

    removeToken(); // 跳转到登录页面

    setTimeout(function () {
      var userAgent = navigator.userAgent;

      if (userAgent.indexOf('DingTalk') > -1) {
        // 钉钉小程序
        // eslint-disable-next-line no-undef
        dd.reLaunch({
          url: '/pages/security/login/index'
        });
      } else {
        // 微信等其他小程序
        // eslint-disable-next-line no-undef
        uni.reLaunch({
          url: '/pages/security/login/index'
        });
      }
    }, 2000);
  }

  return res.data;
}, function (error) {
  Toast({
    mes: '网络或服务器异常，请检查您的网络',
    timeout: 1500
  });
  return Promise.reject(error);
});
export default service;