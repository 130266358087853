import "/var/jenkins_home/workspace/\u7763\u8D1Dvideo\u6D4B\u8BD5\u7248/node_modules/core-js/modules/es.array.iterator.js";
import "/var/jenkins_home/workspace/\u7763\u8D1Dvideo\u6D4B\u8BD5\u7248/node_modules/core-js/modules/es.promise.js";
import "/var/jenkins_home/workspace/\u7763\u8D1Dvideo\u6D4B\u8BD5\u7248/node_modules/core-js/modules/es.object.assign.js";
import "/var/jenkins_home/workspace/\u7763\u8D1Dvideo\u6D4B\u8BD5\u7248/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // ydui

import 'vue-ydui/dist/ydui.base.css'; // common css

import '@/assets/styles/common.scss'; // moment 时间格式化

import moment from 'moment/moment';
Vue.prototype.moment = moment; // console

import VConsole from 'vconsole';

if (process.env.NODE_ENV === 'development') {
  new VConsole();
}

import { compress, setFilename } from '@/utils/common';
Vue.prototype.compress = compress;
Vue.prototype.setFilename = setFilename; // 查看大图

import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';
var options = {
  fullscreenEl: false
};
Vue.use(preview, options);
Vue.use(preview); // 动态修改标题

router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  console.log(from);
  next();
});
Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');